import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading } from "./Headings";
import { PrimaryLink as PrimaryLinkBase } from "./Links";
import { PrimaryButton as PrimaryButtonBase } from "./Buttons";
import { MapPin as LocationIcon } from "react-feather";
import { Clock as TimeIcon } from "react-feather";
import ArrowRightIcon from "../assets/svg/arrow-right-icon.svg";
import { navigate } from "gatsby";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pt-8 pb-24`;

const ThreeColumn = tw.div`flex flex-wrap lg:flex-nowrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)<{ textOnLeft: boolean }>((props) => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`,
]);
const CardColumn = tw(
  Column
)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg text-blue-800`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div<{ imageSrc: string }>((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`,
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-blue-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-2 bg-blue-600`;

type Card = {
  imageSrc: string;
  title: string;
  type: string;
  linkUrl: string;
};

const PortfolioTwoCardsWithImage: React.FC<{
  cards: Card[];
  subheading: string;
  linkText: string;
  linkUrl: string;
  cardLinkText: string;
  description: string;
  headingHtmlComponent: JSX.Element;
}> = ({
  cards,
  linkUrl,
  subheading = "Unsere Stellen",
  headingHtmlComponent = (
    <>
      Wir haben noch <br />
      <span tw="text-blue-800">freie Plätze</span>
    </>
  ),
  description = "Wir haben immer noch offene Stellen und suchen nach talentierten Mitarbeiter:innen, die unser Teams verstärken möchten. Bewirb dich jetzt bei uns!",
  linkText = "Alle Stellen ansehen",
  cardLinkText = "Stelle ansehen",
}) => {
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={false}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink href={linkUrl}>
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.title}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardAction onClick={() => navigate(`/${card.linkUrl}`)}>{cardLinkText}</CardAction>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};

export default PortfolioTwoCardsWithImage;    