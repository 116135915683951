import * as React from "react";
import { graphql } from "gatsby";

import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
  StoryData,
  SbGatsbyStory,
} from "gatsby-source-storyblok";
import { HotelType } from "../Common/Types/HotelType";
import Layout from "../Components/Layout";
import { HotelPage } from "../Components/Pages/HotelPage";
import { JobType } from "../Common";

const StoryblokEntry = ({ data }) => {
  let story: SbGatsbyStory = data.storyblokEntry;
  story = useStoryblokState(story) as SbGatsbyStory;

  // check here wether the component is
  // a hotel
  if (story.content.component === "hotel") {
    const hotel = story.content as unknown as HotelType;
    return <HotelPage {...hotel} />
  }

  if(!story.content.body) {
    return <React.Fragment />
  }

  // a regular page
  const components = story.content.body.map((blok) => (
    <StoryblokComponent blok={blok} key={blok._uid} />
  ));

  return (
    <Layout>
      <div {...storyblokEditable(story.content)}>
        {components}
      </div>
    </Layout>
  );
};

export default StoryblokEntry;

export const query = graphql`
  query ($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`;
