import React from "react";
import tw from "twin.macro";
import "styled-components/macro";
import { PageHeaderWithImage } from "./../PageHeaderWithImage";

import { HotelType } from "../../Common";
import Layout from "../Layout";
import VerticalWithAlternateImageAndText from "../VerticalWithAlternateImageAndText";
import GetStartedLight from "../GetStartedLight";
import PortfolioTwoCardsWithImage from "../PortfolioTwoCardsWithImage";
import { GenerateHotelSlug, GenerateJobSlug } from "../../Common/Functions/GenerateSlugs";

function randomIntFromInterval(
  min: number,
  max: number,
  except?: number
): number {
  const number = Math.floor(Math.random() * (max - min) + min);
  return number === except ? randomIntFromInterval(min, max, except) : number;
}



export const HotelPage: React.FC<HotelType> = (hotel) => {
  const firstImage = randomIntFromInterval(0, hotel.images.length);
  const secondImage = randomIntFromInterval(0, hotel.images.length, firstImage);
  const jobIndex = randomIntFromInterval(0, hotel.hotel_jobs.length);
  const cards = [];

  if (hotel.ueberschrift_1) {
    cards.push({
      description: hotel.kurztext_1,
      title: hotel.ueberschrift_1,
      imageSrc: hotel.images[firstImage].filename,
    });
  }

  if (hotel.ueberschrift_2) {
    cards.push({
      description: hotel.kurztext_2,
      title: hotel.ueberschrift_2,
      imageSrc: hotel.images[secondImage].filename,
    });
  }

  // check here wether the component is
  // a hotel
  return (
    <Layout>
      <PageHeaderWithImage heading={hotel.name} imageSrc={hotel.image.filename} subHeading={hotel.city} />
      <VerticalWithAlternateImageAndText
        cards={cards}
        claim={hotel.claim}
        description={hotel.summary}
      />
      <GetStartedLight primaryLinkUrl={`/${GenerateHotelSlug(hotel.name)}/jobs`} secondaryLinkUrl={`mailto:${hotel.mail}`} />
      <PortfolioTwoCardsWithImage
        cards={hotel.hotel_jobs.slice(jobIndex, jobIndex + 2).map((x) => ({
          imageSrc: x.image.filename,
          title: x.name,
          type: x.start_date,
          linkUrl: GenerateJobSlug(hotel.name, x.name)
        }))}
        subheading="Unsere Stellen"
        headingHtmlComponent={
          <>
            Wir haben noch <br />
            <span tw="text-blue-800">freie Plätze</span>
          </>
        }
        linkText="Alle Stellen ansehen"
        linkUrl={`/${GenerateHotelSlug(hotel.name)}/jobs`}
        cardLinkText="Stelle ansehen"
      />
    </Layout>
  );
};
